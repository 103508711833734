import React from 'react'
import PropTypes from 'prop-types'
import {graphql, Link} from 'gatsby'
import Layout from '../components/Layout'
import Content, {HTMLContent} from '../components/Content'
import contentImage from "../img/alfastuc-verf.jpg";

export const SpackenPageTemplate = ({title, content, contentComponent}) => {
    const PageContent = contentComponent || Content

    return (
        <section className="section section--gradient">
            <div className="container">
                <div className="columns">
                    <div className="column is-10 is-offset-1">
                        <div className="section">
                            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                                {title}
                            </h2>
                            <PageContent className="content" content={content}/>
                            <Link className="button is-link offerte-button" to="/contact">
                                VRAAG EEN OFFERTE AAN
                            </Link>
                            <img className="image-content"
                                 src={contentImage}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

SpackenPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

const SpackenPage = ({data}) => {
    const {markdownRemark: post} = data

    return (
        <Layout>
            <SpackenPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                content={post.html}
            />
        </Layout>
    )
}

SpackenPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default SpackenPage

export const SpackenPageQuery = graphql`
  query SpackenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
